html,
body {
  margin: 0;
  padding: 0;
  height: 100vh;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
  font-family: "Inter", sans-serif;
}
*,
:after,
:before {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
#root {
  height: 100vh !important;
  width: 100vw !important;
}

@media (min-width: 0px) {
  html {
    font-size: 75%;
    /*12px*/
  }
}

@media (min-width: 960px) {
  html {
    font-size: 75%;
    /*12px*/
  }
}

@media (min-width: 1080px) {
  html {
    font-size: 75%;
    /*12px*/
  }
}

@media (min-width: 1366px) {
  html {
    font-size: 81.25%;
    /*13px*/
  }
}

@media (min-width: 1536px) {
  html {
    font-size: 87.5%;
    /*14px*/
  }
}

@media (min-width: 1745px) {
  html {
    font-size: 93.75%;
    /*15px*/
  }
}

@media (min-width: 1920px) {
  html {
    font-size: 100%;
    /*16px*/
  }
}

body {
  height: 100%;
}
#webpack-dev-server-client-overlay {
  display: none;
}

#root {
  height: 100vh !important;
}

.react-kanban-board {
  padding-left: 85px;
  padding-bottom: 10px !important;
  /* height: 98vh; */
  /* for drag scroll Start */
  overflow-x: auto;
  /* overflow-y: hidden; */
  /* white-space: nowrap; */
  /* transition: all 0.2s; */
  /* will-change: transform; */
  user-select: none;
  cursor: pointer;
  /* for drag scroll End */
}

.react-kanban-board.active {
  cursor: grabbing;
  cursor: -webkit-grabbing;
}

.react-kanban-card {
  border-radius: 6px;
  min-height: 85px;
  max-width: 300px;
}

[data-rbd-droppable-id]::-webkit-scrollbar,
.react-kanban-board::-webkit-scrollbar {
  /* .react-kanban-board::-webkit-scrollbar { */
  width: 0px;
}

[data-rbd-droppable-id] {
  height: auto !important;
  overflow-x: visible;
  overflow-y: visible;
}

[data-rbd-draggable-id] {
  will-change: transform, opacity;
  transform: translateZ(0);
}

@media only screen and (min-width: 0px) {
  [data-rbd-drag-handle-context-id] div {
    /* width: 100%; */
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -ms-box-sizing: border-box;
    box-sizing: border-box;
  }
}

[data-rbd-droppable-id="board-droppable"] {
  height: initial;
  overflow-x: initial;
  overflow-y: initial;
  white-space: pre-wrap !important;
  display: grid;
  width: 100%;
}

@media only screen and (min-width: 0px) {
  [data-rbd-droppable-id="board-droppable"] {
    grid-template-columns: repeat(1, 1fr);
  }
}

@media only screen and (min-width: 600px) {
  [data-rbd-droppable-id="board-droppable"] {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media only screen and (min-width: 900px) {
  [data-rbd-droppable-id="board-droppable"] {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media only screen and (min-width: 1200px) {
  [data-rbd-droppable-id="board-droppable"] {
    grid-template-columns: repeat(6, 1fr);
  }
}

@media only screen and (min-width: 1500px) {
  [data-rbd-droppable-id="board-droppable"] {
    grid-template-columns: repeat(12, 1fr);
  }
}

.react-kanban-card--dragging {
  box-shadow: 0px 0px 20px 0px gray;
}
.cell-text-truncate  {
  max-width: 100%;
  display: block;
  width: fit-content;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}